// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\TropicalSun25\\Documents\\Projekty\\promotax-main\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-js": () => import("C:\\Users\\TropicalSun25\\Documents\\Projekty\\promotax-main\\src\\templates\\index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-aktualnosci-js": () => import("C:\\Users\\TropicalSun25\\Documents\\Projekty\\promotax-main\\src\\pages\\aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-cennik-js": () => import("C:\\Users\\TropicalSun25\\Documents\\Projekty\\promotax-main\\src\\pages\\cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\TropicalSun25\\Documents\\Projekty\\promotax-main\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("C:\\Users\\TropicalSun25\\Documents\\Projekty\\promotax-main\\src\\pages\\kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-zakres-uslug-js": () => import("C:\\Users\\TropicalSun25\\Documents\\Projekty\\promotax-main\\src\\pages\\zakres-uslug.js" /* webpackChunkName: "component---src-pages-zakres-uslug-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\TropicalSun25\\Documents\\Projekty\\promotax-main\\.cache\\data.json")

