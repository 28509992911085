module.exports = [{
      plugin: require('C:/Users/TropicalSun25/Documents/Projekty/promotax-main/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;"},
    },{
      plugin: require('C:/Users/TropicalSun25/Documents/Projekty/promotax-main/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-115104349-1"},
    },{
      plugin: require('C:/Users/TropicalSun25/Documents/Projekty/promotax-main/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/TropicalSun25/Documents/Projekty/promotax-main/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/TropicalSun25/Documents/Projekty/promotax-main/node_modules/gatsby-plugin-react-next/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/TropicalSun25/Documents/Projekty/promotax-main/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
